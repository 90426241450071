<template>
  <div class="container">
    <dataInfoDY
      :platform="platform"
      :roomId="roomId"
      :authorInfo="authorInfo"
      :principalId="principalId"
      :screenData="screenDataDY"
      v-if="platform == 1"
    />
    <div class="content-wrap">
      <div class="left">
        <div class="bg-white">
          <liveInfo
            :platform="platform"
            :roomId="roomId"
            :authorInfo="authorInfo"
            :principalId="principalId"
            :changeTime="changeTime"
            @activeTime="setActiveTime"
          />
        </div>
      </div>
      <div class="right">
        <div class="bg-white" style="margin-bottom: 16px;" v-if="platform == 2">
          <dataInfo
            :authorInfo="authorInfo"
          />
        </div>
        <div class="bg-white">
          <trendInfoDY
            :roomId="roomId"
            :platform="platform"
            :principalId="principalId"
            :authorInfo="authorInfo"
            :activeTime="activeTime"
            :videoData="videoData"
            :screenData="screenDataDY"
            @changeTime="changeTimeVal"
            v-if="platform == 1"
          />
          <trendInfo
            :roomId="roomId"
            :platform="platform"
            :principalId="principalId"
            :authorInfo="authorInfo"
            :activeTime="activeTime"
            :videoData="videoData"
            @changeTime="changeTimeVal"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liveInfo from './components/liveInfo'
import dataInfo from './components/dataInfo'
import trendInfo from './components/trendInfo'
import dataInfoDY from './components/dataInfoDY'
import trendInfoDY from './components/trendInfoDY'
import liveApi from '@/api/live'

export default {
  components: {
    liveInfo,
    dataInfo,
    trendInfo,
    dataInfoDY,
    trendInfoDY,
  },
  data() {
    return {
      roomId: this.$route.params.roomId,
      platform: this.$route.query.platform,
      principalId: this.$route.query.principalId,
      authorInfo: {},
      activeTime: undefined,
      videoData: {},
      changeTime: undefined,
      screenDataDY: {},
    }
  },
  created() {
    this.getLiveDetail()
    this.getLiveVideo()
    if(this.platform == 1) {
      this.getScreenDataDY()
    }
  },
  methods: {
    getLiveDetail() {
      let params = {
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.getLiveDetail(params).then(res => {
        if(res.code == 200) {
          this.authorInfo = res.data || {}
        } else {
          this.$message.error(`获取详情失败，${res.message}`)
        }
      })
    },
    getScreenDataDY() {
      let params = {
        platform: this.platform,
        roomId: this.roomId
      }
      liveApi.getScreenDataDY(params).then(res => {
        if(res.code == 200) {
          this.screenDataDY = res.data || {}
        } else {
          this.$message.error(`获取数据看板失败，${res.message}`)
        }
      })
    },
    getLiveVideo() {
      let params = {
        room_ids: this.roomId,
        source: this.platform == 1 ? 'p9' : undefined
      }
      liveApi.getLiveVideo(params).then(res => {
        if(res.code == 200) {
          this.videoData = res.data[0] || {}

        } else {
          this.$message.error(`获取视频失败，${res.message}`)
        }
      })
    },
    setActiveTime(val) {
      this.activeTime = Math.floor(Number(val))
    },
    changeTimeVal(time) {
      this.changeTime = time
    }
  }
}
</script>

<style scoped lang="less">
.container {
  .content-wrap {
    display: flex;
    .left {
      margin-right: 16px;
      width: 330px;
    }
    .right {
      flex: 1;
    }
  }
}
.bg-white {
  background-color: #fff;
}
</style>