var option = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        padding: [5, 10],
        position: function (point, params, dom, rect, size) {
            return {right: 0, top: point[1] / 2};
        },
        formatter: '{b}<br />{c}%'
    },
    legend: {},
    grid: {
        top: '10',
        left: '0',
        right: '10',
        bottom: '0',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        splitLine: {
            show: false
        },
        axisLabel: {
            show: false,
        },
    },
    yAxis: {
        type: 'category',
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            color: '#fff',
            fontSize: 12,
        }
    },
    series: [{
        type: 'bar',
        barWidth: 15,
        itemStyle: {
            color: '#fff',
        },
        label: {
            show: true,
            position: 'right',
            fontSize: 10,
            formatter: '{c}%'
        },
    }]
}

export default option