<template>
  <div class="data-wrap">
    <template v-if="showData">
      <div class="box box-1">
        <div class="main-title">用户画像</div>
        <a-tabs v-model="userActive" @change="onClickUser" v-if="(screenData.watchProfile || []).length > 0">
          <a-tab-pane key="1" tab="年龄">
            <div class="chart">
              <BaseCharts
                ref="BarCharts"
                key="bar"
                :style="{width:'100%',height:'100%'}"
                :option="getBarChartOption(1)"
              />
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="地域" force-render>
            <div class="region">
              <div class="chart">
                <BaseCharts
                  ref="BarCharts"
                  key="bar"
                  :style="{width:'100%',height:'100%'}"
                  :option="getBarChartOption(2)"
                />
              </div>
              <div class="chart">
                <BaseCharts
                  ref="BarCharts"
                  key="bar"
                  :style="{width:'100%',height:'100%'}"
                  :option="getBarChartOption(3)"
                />
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <div class="chart no-result" v-else>统计数据处理中，请稍候</div>
      </div>
      <div class="box box-2">
        <div class="main-title">流量转化</div>
        <div class="transform-box" v-if="screenData.convert && screenData.convert.length > 0">
          <div class="transform-title">
            <div class="title"><span>直播间曝光人数</span></div>
            <div class="title"><span>直播进入人数</span></div>
            <div class="title"><span>线索工具点击人数</span></div>
            <div class="title"><span>全场景线索人数</span></div>
          </div>
          <div class="transform-data">
            <div class="transform-bg"><img :src="require('@/assets/images/liveReviewAssistant/transform-bg.svg')" /></div>
            <div class="content">
              <div class="num">{{ (screenData.convert[0] || {}).lpScreenLiveShowUv | humanReadable }}</div>
              <div class="row">转化率 {{ (screenData.convert[0] || {}).lpScreenLiveWatchUv, (screenData.convert[0] || {}).lpScreenLiveShowUv | conversionRate }}</div>
              <div class="num">{{ (screenData.convert[0] || {}).lpScreenLiveWatchUv | humanReadable }}</div>
              <div class="row">转化率 {{ (screenData.convert[0] || {}).lpScreenLiveCardClickUv, (screenData.convert[0] || {}).lpScreenLiveWatchUv | conversionRate }}</div>
              <div class="num">{{ (screenData.convert[0] || {}).lpScreenLiveCardClickUv | humanReadable }}</div>
              <div class="row">转化率 {{ (screenData.convert[0] || {}).lpScreenClueUv, (screenData.convert[0] || {}).lpScreenLiveCardClickUv | conversionRate }}</div>
              <div class="num">{{ (screenData.convert[0] || {}).lpScreenClueUv | humanReadable }}</div>
            </div>
          </div>
          <div class="transform-rate">
            <div>
              <div>转化率</div>
              <div>{{ (screenData.convert[0] || {}).lpScreenClueUv, (screenData.convert[0] || {}).lpScreenLiveWatchUv | conversionRate }}</div>
            </div>
          </div>
        </div>
        <div class="chart no-result" v-else>统计数据处理中，请稍候</div>
      </div>
      <div class="box box-3">
        <div class="main-title">人气互动</div>
        <div class="data-box">
          <div class="content">
            <div class="title">人均观看时长</div>
            <div class="data">{{ dataInfo.lpScreenLiveAvgWatchDuration | formatSec }}</div>
            <div class="tag"><span>粉丝停留 {{ dataInfo.lpScreenLiveFansAvgWatchDurationOrig | formatSec }}</span></div>
          </div>
          <div class="content">
            <div class="title">累计观看人数</div>
            <div class="data">{{ dataInfo.lpScreenLiveWatchUv | humanReadable }}</div>
            <div class="tag"><span>粉丝占比 {{ dataInfo.lpScreenLiveFansWatchRatio | humanRate }}</span></div>
          </div>
          <div class="content">
            <div class="title">最高在线人数</div>
            <div class="data">{{ dataInfo.lpScreenLiveMaxWatchUvByMinute | humanReadable }}</div>
            <div class="tag"><span>平均 {{ dataInfo.lpScreenLiveAvgOnlineUvByRoom | humanReadable }}</span></div>
          </div>
          <div class="content">
            <div class="title">关注率</div>
            <div class="data">{{ dataInfo.lpScreenLiveFollowRatio | humanRate }}</div>
            <div class="tag"><span>增粉量 {{ dataInfo.lpScreenLiveFollowUv | humanReadable }}</span></div>
          </div>
          <div class="content">
            <div class="title">评论率</div>
            <div class="data">{{ dataInfo.lpScreenLiveCommentRatio | humanRate }}</div>
            <div class="tag"><span>评论量 {{ dataInfo.lpScreenLiveCommentCount | humanReadable }}</span></div>
          </div>
          <div class="content">
            <div class="title">分享率</div>
            <div class="data">{{ dataInfo.lpScreenLiveShareRatio | humanRate }}</div>
            <div class="tag"><span>分享量 {{ dataInfo.lpScreenLiveShareCount | humanReadable }}</span></div>
          </div>
        </div>
      </div>
      <div class="box box-4">
        <div class="main-title">线索留资</div>
        <div class="data-box">
          <div class="content">
            <div class="title">私信人数</div>
            <div class="data">{{ dataInfo.lpScreenMsgConversationCount | humanReadable }}</div>
            <div class="tag"><span>私信长效转化 {{ dataInfo.lpScreenLongtermMsgClueUv | humanReadable }}</span></div>
          </div>
          <div class="content">
            <div class="title">线索量</div>
            <div class="data">{{ authorInfo.salesClueCount | humanReadable }}</div>
          </div>
          <div class="content">
            <div class="title">小风车点击次数</div>
            <div class="data">{{ dataInfo.lpScreenLiveIconClickCount | humanReadable }}</div>
            <div class="tag"><span>点击率 {{ dataInfo.lpScreenLiveIconClickRate | humanRate }}</span></div>
          </div>
          <div class="content">
            <div class="title">卡片点击次数</div>
            <div class="data">{{ dataInfo.lpScreenLiveClueBusinessCardClickCount | humanReadable }}</div>
            <div class="tag"><span>点击率 {{ dataInfo.lpScreenLiveClueBusinessCardClickRate | humanRate }}</span></div>
          </div>
        </div>
      </div>
    </template>
    <div style="flex: 1; display: flex; align-items: center; justify-content: center; height: 200px;" v-else>
      统计数据处理中，请稍候
    </div>
  </div>
</template>

<script>
import chartBar from "../chartBar";
import deepClone from "@/utils/deepClone";
import BaseCharts from "@/components/BaseChart";
import liveApi from '@/api/live'
import MathUtil from "@/utils/MathUtil";

const mathUtil = new MathUtil();

export default {
  name: 'dataInfoDY',
  props: {
    roomId: String,
    principalId: String,
    authorInfo: {
      type: Object,
      default: () => {}
    },
    platform: String,
    screenData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseCharts
  },
  data() {
    return {
      userActive: '1',
      dataInfo: {},
      showData: false
    }
  },
  filters: {
    formatSec(second) {
      if((second ?? '') === '') {
        return '-'
      }

      let seconds = Math.floor(second)
      let daySec = 24 * 60 * 60;
      let hourSec = 60 * 60;
      let minuteSec = 60;
      let dd = Math.floor(seconds / daySec);
      let hh = Math.floor((seconds % daySec) / hourSec);
      let mm = Math.floor((seconds % hourSec) / minuteSec);
      let ss = seconds % minuteSec;
      if(dd > 0) {
        return `${dd}天${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
      }
      if(hh > 0) {
        return `${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
      }
      if(mm > 0) {
        return `${mm}分钟${ss ? (ss + "秒") : ''}`;
      } else {
        return ss + "秒";
      }
    },
    humanReadable(val, scale = 1, other = "0") {
      if((val ?? '') === '') {
        return '-'
      }

      scale = typeof scale == "number" ? scale : 1;
      other = typeof other == "string" ? other : "0";
      const t = Math.pow(10, scale);
      if(val == undefined) {
        val = other;
      } else if(typeof val != "number") {
        val = "0";
      } else if(val >= 1e8) {
        val = Math.floor(val / (1e8 / t)) / t + "亿";
      } else if(val >= 1e4) {
        val = Math.floor(val / (1e4 / t)) / t + "w";
      } else if (val <= -1e4) {
        val = Math.floor(val / (1e4 / t)) / t + "w";
      } else if(val <= -1e8) {
        val = Math.floor(val / (1e8 / t)) / t + "亿";
      } else if(val != 0) {
        val = Math.floor(mathUtil.multiply(val * t)) / t;
      }
      return val;
    },
    humanRate(val) {
      if((val ?? '') !== '') {
        return `${Number((val * 100).toString().match(/^\d+(?:.\d{0,2})?/)).toFixed(2)}%`
      } else {
        return '-'
      }
    },
    conversionRate(val1, val2) {
      if((val1 ?? '') !== '' && val2) {
        return `${Number((val1 / val2 * 100).toString().match(/^\d+(?:.\d{0,2})?/)).toFixed(2)}%`
      }

      return '-'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getOverviewDY()
    })
  },
  methods: {
    getOverviewDY() {
      let params = {
        platform: this.platform,
        roomId: this.roomId
      }
      liveApi.getOverviewDY(params).then(res => {
        if(res.code == 200) {
          this.dataInfo = res.data || {}
          this.showData = Object.keys(this.dataInfo).length > 0
        } else {
          this.$message.error(`获取数据看板失败，${res.message}`)
        }
      })
    },
    onClickUser() {},
    //图表
    getBarChartOption(type) {
      let option = null;
      if (!option) {
          option = deepClone(chartBar);
      }

      let xAxis = []
      let yAxis = []
      let data = {}
      let base = (this.screenData.watchProfile || [])[0] || {}

      switch(type) {
        case 1:
          try {
            data = JSON.parse((base.lpScreenLiveWatchProfileAge).replace(/23/, '23岁').replace(/24-30/, '24-30岁').replace(/31-40/, '31-40岁').replace(/41-50/, '41-50岁').replace(/50-/, '50岁以上'))
          } catch (err) {
            data = {}
          }
          xAxis = Object.values(data).reverse()
          yAxis = Object.keys(data).reverse()
          break;
        case 2:
          data = base.lpScreenLiveWatchProfileCity ? JSON.parse(base.lpScreenLiveWatchProfileCity) : {}
          xAxis = this.formatData(data, 'value', 'even')
          yAxis = this.formatData(data, 'key', 'even')
          break;
        case 3:
          data = base.lpScreenLiveWatchProfileCity ? JSON.parse(base.lpScreenLiveWatchProfileCity) : {}
          xAxis = this.formatData(data, 'value', 'odd')
          yAxis = this.formatData(data, 'key', 'odd')
          break;
      }

      option.yAxis.data = yAxis
      option.series[0].data = xAxis
      
      return option;
    },
    formatData(data, type, parity) {
      let arr = []
      
      switch(type) {
        case 'key':
          arr = Object.keys(data).sort(function(a, b) {return data[a] - data[b]})
          break;
        case 'value':
          arr = Object.values(data).sort(function(a, b) {return a - b})
          break;
      }

      switch(parity) {
        case 'odd':
          arr = arr.filter((item, index) => index % 2 === 0)
          break;
        case 'even':
          arr = arr.filter((item, index) => index % 2 !== 0)
          break;
      }

      return arr
    },
  },
}
</script>

<style scoped lang="less">
.data-wrap {
  display: flex;
  margin-bottom: 16px;
  background-color: rgba(24, 144, 255, .7);
  color: #fff;
  .box {
    padding: 10px;
    & +.box {
      border-left: 1px solid #f0f2f5;
    }
    &-1 {
      width: 24%;
    }
    &-2 {
      // width: 29%;
      width: 25%;
      min-width: 330px;
    }
    &-3 {
      flex: 1;
      .content {
        width: 32%;
      }
    }
    &-4 {
      width: 18%;
      .content {
        width: 48.5%;
      }
    }
  }
  .main-title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .region {
    display: flex;
    .chart {
      width: 50%;
    }
  }
  .chart {
    height: 150px;
  }
  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
  }

  .transform-box {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    .transform-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1% 10px 1% 0;
      .title {
        position: relative;
        height: 1.5em;
        text-align: right;
        span {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
    .transform-data {
      position: relative;
      width: 45%;
      min-width: 150px;
      .transform-bg {
        img {
          width: 100%;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        padding: 3% 0;
        width: 100%;
        height: 100%;
        .num {
          color: #333;
        }
      }
    }
    .transform-rate {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 10%;
      width: 15%;
      min-width: 50px;
      text-align: center;
    }
  }

  .data-box {
    display: flex;
    flex-wrap: wrap;
    align-items: top;
    justify-content: space-around;
  }
  .content {
    text-align: center;
    margin-bottom: 15px;
    word-break: break-word;
    .title {
      font-size: 12px;
    }
    .data {
      margin: 5px 0;
      font-size: 14px;
      font-weight: bold;
    }
    .tag {
      // display: flex;
      // justify-content: center;
      span {
        display: inline-block;
        padding: 1px 8px;
        font-size: 10px;
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 10px;
      }
    }
  }
}
/deep/ .ant-tabs-bar {
  border-bottom: none;
}
/deep/ .ant-tabs-nav {
  .ant-tabs-tab {
    padding: 5px 10px;
    margin-right: 20px;
    color: #fff;
  }
  .ant-tabs-tab-active {
    color: #fff;
  }
}
/deep/ .ant-tabs-ink-bar {
  background-color: #fff;
}
</style>