<template>
  <div class="data-box">
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsTotalWatchUserCount | humanReadable }}
      </div>
      <div class="label">观看人数</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsTotalWatchUserTime | humanReadable }}
      </div>
      <div class="label">观看人次</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsMaxOnlineUser | humanReadable }}
      </div>
      <div class="label">最高在线人数</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsAvgOnlineUser | humanReadable }}
      </div>
      <div class="label">平均在线人数</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsTotalLikeCount | humanReadable }}
      </div>
      <div class="label">点赞量</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsTotalCommentCount | humanReadable }}
      </div>
      <div class="label">评论量</div>
    </div>
    <!--
    <div class="item">
      <div class="value">
        {{ authorInfo.statisticsFansAdd | humanReadable }}
      </div>
      <div class="label">增粉量</div>
    </div>
    <div class="item">
      <div class="value">
        {{ authorInfo.salesClueCount | humanReadable }}
      </div>
      <div class="label">线索量</div>
    </div>
    -->
  </div>
</template>

<script>
import liveApi from '@/api/live'

export default {
  props: {
    authorInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">
.data-box {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  .item {
    margin: 9px;
    min-width: 100px;
    text-align: center;
  }
  .value {
    font-size: 20px;
    font-weight: bold;
  }
  .label {
    font-size: 14px;
  }
}
</style>