<template>
  <div class="info-box">
    <div class="dealer-info">
      <div class="avatar">
        <a-avatar
          :src="authorInfo.avatar"
          :size="50"
          icon="user"
        />
        <i class="icon" v-if="authorInfo.verificationType == 2 || authorInfo.verificationType == 10"></i>
      </div>
      <div class="info">
        <p class="name">{{ authorInfo.nickname || "-" }}</p>
        <p class="code align-center">
          <i :class="[
            'icon',
            {
              'icon-douyin': authorInfo.platform == 'douyin',
              'icon-kuaishou': authorInfo.platform == 'kuaishou'
            }
          ]"/>
          媒体号：{{ authorInfo.code || "-" }}
        </p>
        <p class="data">
          数据对齐：
          <a-tag :color="authorInfo.p9OauthStatus | filterColor" v-if="platform == 1">线索数据<a-icon type="check-circle" v-if="authorInfo.p9OauthStatus == 1 || authorInfo.p9OauthStatus == 3" /><a-icon type="exclamation-circle" class="exclamation" v-else /></a-tag>
          <a-tag :color="authorInfo.p1OauthStatus | filterColor" v-else>直播数据<a-icon type="check-circle" v-if="authorInfo.p1OauthStatus == 1 || authorInfo.p1OauthStatus == 3" /><a-icon type="exclamation-circle" class="exclamation" v-else /></a-tag>
        </p>
        <p class="dealer">
          <span class="dealer-icon"></span>
          <span v-if="authorInfo.dealerId">{{ authorInfo.dealerName || "-" }}({{ authorInfo.dealerStoreCode || "-" }})</span>
          <span v-else>-</span>
        </p>
      </div>
    </div>

    <div class="live-info">
      <div class="title-box">
        <div class="tag" v-if="authorInfo.roomStatus">{{ authorInfo.roomStatus == 4 ? '直播已结束' : authorInfo.roomStatus == 2 ? '直播进行中' : '-' }}</div>
        <div class="title">{{ authorInfo.title || '-' }}</div>
      </div>
      <div class="row"><span>平台：</span>{{ authorInfo.platform == 'douyin' ? '抖音' : authorInfo.platform == 'kuaishou' ? '快手' : '-' }}</div>
      <div class="row"><span>直播时长：</span>{{ (authorInfo.statisticsLiveTime || 0) | formatSecToStr }}</div>
      <div class="row"><span>开播时间：</span>{{ authorInfo.publishTime || '-' }}</div>
      <div class="row"><span>下播时间：</span>{{ authorInfo.finishTime || '-' }}</div>
      
      <template v-if="platform == 2">
        <div class="video-box" v-if="videoData.url">
          <video id="videoElement" :src="videoData.url" controls muted></video>
          <div class="lock pos-box" v-if="(videoData.state == 'Archive' || videoData.state == 'Unknown') && (videoData.is_restore_completed === 0 || videoData.is_restore_completed === 3)">
            <a-button type="primary" icon="unlock" :loading="lockLoading" @click="unlockClick">解冻</a-button>
          </div>
          <div class="generate pos-box" v-if="(videoData.state == 'Archive' || videoData.state == 'Unknown') && videoData.is_restore_completed === 1">
            解冻中
          </div>
          <div class="play pos-box" v-if="(videoData.is_restore_completed === 2 || videoData.state == 'Standard' || videoData.state == 'IA') && !isPlay" @click="openLive(videoData)">
            <a-icon type="play-circle" />
          </div>
        </div>
        <div class="video-box" v-else>
          <div class="generate pos-box">暂无回放</div>
        </div>
      </template>
      <template v-if="platform == 1">
        <div class="video-box" v-if="videoData.m3u8 || videoData.url">
          <xg-player
            :id="'livePlayer'"
            :url="videoData.m3u8 || videoData.url"
            :changeTime="changeTime"
            :isHls="videoData.m3u8 ? true : false"
            @xgPlayerEvent="xgPlayerEvent"
          />
          <div class="generate generate-1 lock pos-box" v-if="(videoData.state == 'Archive' || videoData.state == 'Unknown') && (videoData.is_restore_completed === 0 || videoData.is_restore_completed === 3)">
            <a-button type="primary" icon="unlock" :loading="lockLoading" @click="unlockClick">解冻</a-button>
          </div>
          <div class="generate generate-1 pos-box" v-if="(videoData.state == 'Archive' || videoData.state == 'Unknown') && videoData.is_restore_completed === 1">
            解冻中
          </div>
        </div>
        <div class="video-box" v-else>
          <div class="generate pos-box">暂无回放</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import liveApi from '@/api/live'
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import xgPlayer from "@/components/xgPlayer/xgplayer"

export default {
  name: 'liveInfo',
  props: {
    roomId: String,
    principalId: String,
    authorInfo: {
      type: Object,
      default: () => {}
    },
    changeTime: [Number, String],
    platform: String,
  },
  components: {
    xgPlayer
  },
  filters: {
    filterColor(type) {
      let color = ''
      switch(type) {
        case 0:
        case 2:
          color = 'orange'
          break;
        case 1:
        case 3:
          color = 'green'
          break;
      }
      return color
    }
  },
  watch: {
    changeTime: {
      handler: function(val) {
        if(this.platform == 2) {
          this.openLive(this.videoData, val)
        }
      }
    }
  },
  data() {
    return {
      loacationOrigin: location.origin,
      isPlay: false,
      lockLoading: false,
      activeTime: 0,
      videoData: {},
    }
  },
  mounted() {
    this.getLiveVideo()
  },
  methods: {
    getLiveVideo() {
      let params = {
        room_ids: this.roomId,
        source: this.platform == 1 ? 'p9' : undefined
      }
      liveApi.getLiveVideo(params).then(res => {
        if(res.code == 200) {
          this.videoData = res.data[0] || {}
          // this.videoData.url = 'https://lf3-record-tos.bytefcdn.com/obj/fcdn-dy/59959355621791160481/push-rtmp-hs-f5.douyincdn.com_stage_stream-401673653562639018_1690436577_1690440198_record.m3u8?psm=ad.platform.live_console'
        } else {
          this.$message.error(`获取视频失败，${res.message}`)
        }
      })
    },
    unlockClick() {
      if (this.lockLoading) {
        return false;
      }
      // 调用解冻接口
      this.lockLoading = true;
      let body = {
        room_id: this.roomId,
        principal_id: this.principalId,
        source: this.platform == 1 ? 'p9' : undefined
      };
      liveApi.restoreLiveVideo(body).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("解冻成功");
          this.lockLoading = false;
          this.getLiveVideo();
        } else {
          this.$message.error("解冻失败");
          this.lockLoading = false;
        }
      });
    },
    openLive(detail, time = 0) {
      if (detail.url) {
        if (detail.state == "Archive" || detail.state == "Unknown") {
          if (
            detail.is_restore_completed == "0" ||
            detail.is_restore_completed == "3"
          ) {
            this.$message.warning(
              "直播视频已被冻结,可点击解冻按钮解冻 ( 预计1分钟解冻 )"
            );
            return false;
          } else if (detail.is_restore_completed == "1") {
            this.$message.warning(
              "解冻中,可点击解冻记录查看进度 ( 24h 后冻结 )"
            );
            return false;
          }
        }
        this.isPlay = true
        this.getStream(this.videoData.url, time);
      } else {
        this.$message.warning("暂无该直播视频，请联系管理员...");
      }
    },
    // 初始化flv
    getStream(source, time = 0) {
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          let that = this
          this.flvPlayer = flvjs.createPlayer({
            type: source.indexOf(".mp4") > 0 ? "mp4" : "flv",
            url: source,
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.currentTime = Number(time) || 0;
          this.flvPlayer.on("error", (err) => {
            this.$message.warning("暂无该直播视频，请联系管理员...");
          });

          videoElement.addEventListener('play', function () {
            const targetTime = videoElement.currentTime;
            that.activeTime = targetTime
            that.$emit('activeTime', that.toMin(targetTime))
          });

          videoElement.addEventListener('seeked', function() {
            const targetTime = videoElement.currentTime;
            that.activeTime = targetTime
            that.$emit('activeTime', that.toMin(targetTime))
          });

          videoElement.addEventListener('playing', function () {
            setInterval(() => {
              const targetTime = videoElement.currentTime;
              if(that.activeTime != that.toMin(targetTime)) {
                that.activeTime = targetTime
                that.$emit('activeTime', that.toMin(targetTime))
              }
            }, 1000);
          });
        }
      });
    },
    toMin(time) {
      if (typeof (time) != 'number') {
        return '-'
      }
      return Math.floor(time / 60)
    },
    xgPlayerEvent(currentTime) {
      this.$emit('activeTime', currentTime)
    }
  }
}
</script>

<style scoped lang="less">
.info-box {
  padding: 20px 10px;
}

.align-center {
  display: flex;
  align-items: center;
}

.dealer-info {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  .avatar {
    position: relative;
    width: 50px;
    height: 50px;
    .icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 16px;
      height: 16px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../../assets/b_v.webp");
    }
  }
  .info {
    flex: 1;
    margin-left: 10px;
    p {
      margin: 0 0 5px;
    }
    .name {
      font-weight: bold;
    }
    .code {
      font-size: 12px;
      color: #999;
      .icon {
        display: inline-block;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        &-douyin {
          margin: 0 8px 0 0;
          width: 18px;
          height: 18px;
          background-image: url("../../../../assets/icon/icon_douyin.svg");
        }

        &-kuaishou {
          margin: 0 8px 0 0;
          width: 18px;
          height: 18px;
          background-image: url("../../../../assets/icon/icon_kuaishou.svg");
        }
      }
    }
    .data {
      margin-left: 26px;
      font-size: 12px;
      color: #999;
      .exclamation {
        margin-left: 4px;
      }
    }
    .dealer {
      display: flex;
      align-items: center;
      font-size: 12px;
      // color: red;
    }
    .dealer-icon {
      display: inline-block;
      margin: 0 8px 0 0;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../../assets/icon/store-icon.svg");
    }
  }
}

.live-info {
  .title-box {
    display: flex;
    align-items: flex-start;
    .tag {
      padding: 0px 6px;
      margin-top: 2px;
      margin-right: 10px;
      font-size: 12px;
      color: rgba(255, 255, 255, .8);
      background-color: #000;
      white-space: nowrap;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .row {
    margin: 5px 0;
    font-size: 12px;
    span {
      color: #999;
    }
  }
  .video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 15px;
    width: 100%;
    min-height: 300px;
    border-radius: 8px;
    overflow: hidden;
    // background-color: rgba(0, 0, 0, .05);
    video {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
    .pos-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
    .generate {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      text-align: center;
      background-color: rgba(0, 0, 0, .2);
      &-1 {
        background-color: rgba(0, 0, 0, .8);
      }
    }
    .play {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>