<template>
  <div class="trend-box">
    <div class="main-title" style="padding: 20px 20px 0;">综合趋势</div>
    <div class="chart" v-if="(screenData.trend || []).length > 0">
      <BaseCharts
        ref="BaseChartsDY"
        key="line"
        :style="{width:'100%',height:'100%'}"
        :option="getLineChartOption()"
        :isLimitLegend="true"
        :limitLength="3"
        :isClick="true"
        @thisIndex="thisIndex"
        @getLegend="getLegend"
      />
    </div>
    <div v-else style="display: flex; align-items: center; justify-content: center; height: 300px;">
      统计数据处理中，请稍候
    </div>

    <div class="content">
      <div class="note">提示：双击话术/评论时间联动直播回放及趋势数据</div>
      <div class="header-box">
        <div class="row">
          <div class="col">
            <div class="main-title">主播话术 <a-button type="link" size="large" :disabled="!isQueryAudioText || isExport" :loading="isExport" style="height: 20px" @click="exportLiveTextDY"><a-icon type="download" /></a-button></div>
          </div>
          <div class="col">
            <div class="main-title">用户评论({{ textAndCommentStatus.commentStatus == 5 ? commentTotal : 0 }})</div>
          </div>
        </div>
      </div>
      
      <div :class="['board-content', isQueryAudioText ? 'border' : '']">
        <div class="anchor-point" v-if="isQueryAudioText" @click="backAnchorPoint">
          <a-tooltip placement="left">
            <template slot="title">
              <span>回到当前播放位置</span>
            </template>
            <div class="anchor"></div>
          </a-tooltip>
        </div>
        <div :class="['board-wrap', isQueryAudioText ? 'is-convert' : 'un-convert']" id="boardWrap">
          <div :class="['row', isFilterActive(item) ? 'active' : '']" :id="`${filterTime(item.splitStartTime)}`" v-for="(item, index) in (textAndCommentContent || [])" :key="item.id">
            <div class="col" v-if="isQueryAudioText">
              <div class="time-point time-click" @dblclick="goCurrentTime(item.splitStartTime)">{{ filterTime(item.splitStartTime) }}</div>
              <div class="detail">{{ item.text }}</div>
            </div>
            <div class="col" v-else>
              <div class="convert-wrap">
                <div class="convert-box">
                  <div v-if="textAndCommentStatus.textStatus == 5">
                    <div class="txt" v-if="textAndCommentStatus.textTurnStatus == 'PROCESSING'">转写中，预计10分钟完成</div>
                    <div class="txt" v-if="textAndCommentStatus.textTurnStatus == 'FAIL'">额度超限，暂不可转</div>
                  </div>
                  <div v-else>
                    <div class="txt">处理中，预计1小时内完成</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col message-wrap">
              <div v-if="textAndCommentStatus.commentStatus == 5">
                <div v-if="index == 0 && commentTotal == 0">
                  暂无评论
                </div>
                <div class="message-box" id="messageBox" v-if="commentTotal > 0">
                  <div :class="['message', !isQueryAudioText && messageActiveIndex === mIndex ? 'active' : '']" v-for="(list, mIndex) in (item.commentDataArray || [])" :key="list.id" :id="`${filterTime(list.publish_time)}`">
                    <span class="time time-click" @dblclick="goCurrentTime(list.publish_time)">{{ filterTime(list.publish_time) }}</span>
                    <span class="time">{{ (list || {}).user.nickname || '-' }}：</span>{{ list.content || '-' }}
                  </div>
                </div>
              </div>
              <div v-else>评论数据处理中，请稍候</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liveApi from '@/api/live'
import BaseCharts from "@/components/BaseChart";
import echarts from "echarts";
import moment from 'moment';
import { downloadExcel } from "@/utils";
import FormatUtil from "@/utils/FormatUtil";

const colors = ["40, 189, 69", "255, 202, 92", "254, 140, 140", "186, 140, 254", "129, 175, 255", "92, 230, 241", "244, 50, 199", "14, 169, 221", "159, 221, 14", "221, 154, 14", "221, 14, 14", "40, 189, 69", "255, 202, 92", "254, 140, 140"]

export default {
  props: {
    roomId: String,
    platform: String,
    principalId: String,
    authorInfo: {
      type: Object,
      default: () => {}
    },
    activeTime: [String, Number],
    videoData: {
      type: Object,
      default: () => {}
    },
    screenData: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BaseCharts
  },
  watch: {
    authorInfo: {
      handler: function(data) {
        if(moment(new Date()).subtract(1,'months') > moment(data.publishTime)) {
          this.isTimeout = true
        }
        this.recordStartTime = data.publishTime
      },
      immediate: true,
      deep: true
    },
    activeTime: {
      handler: function(val, valOld) {
        if(val == valOld) {
          return
        }

        if((val ?? '') !== '') {
          let screenData = this.screenData
          if(screenData['statTimeBySecond']) {
            // let index = screenData['liveTimePointDuration'].indexOf(val)
            // let rang = (screenData['liveTimePointDuration'] || []).length ? Number((val / (screenData['liveTimePointDuration'] || []).length * 100).toFixed(0)) : 0
            // rang < 10 ? 10 : rang

            this.nowActiveTime = (moment(this.recordStartTime).add(val, 'seconds')).format('YYYY-MM-DD HH:mm:ss')
            // this.rangZoom = [rang-10 , rang+10]

            if(!this.isQueryAudioText) {
              let list = this.textAndCommentContent[0].commentDataArray || []
              for(let i = 0; i < list.length; i++) {
                let start = list[i].publish_time
                console.log({i})
                if(start == this.nowActiveTime || i == list.length - 1) {
                  this.messageActiveIndex = i
                  break
                } else if(start > this.nowActiveTime) {
                  this.messageActiveIndex = i ? (i - 1) : i
                  break
                }
              }
            }

            this.fixedTextScroll()
            this.scrollCount = 0
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      isQueryAudioText: false,
      textAndCommentContent: [],
      commentTotal: 0,
      isExport: false,
      nowActiveTime: '',
      rangZoom:[0, 20],
      isTimeout: false,
      isScroll: true,
      scrollCount: 0,
      recordStartTime: '',
      // chartData: {},
      messageActiveIndex: undefined,
      legendList: {},
      textAndCommentStatus: {},
    }
  },
  created() {
    this.getTextAndCommentStatusDY('edouyin-live-reply')
    this.getTextAndCommentStatusDY('edouyin-live-comment').then(() => {
      this.getLiveTextDY()
    })
  },
  mounted() {
    var that = this
    document.getElementById('boardWrap').addEventListener("scroll", function () {
      that.scrollCount++;
      if(that.scrollCount > 5) {
        that.isScroll = false
      }
    })
  },
  methods: {
    filterTime(time) {
      return time ? moment(time).format('HH:mm:ss') : '-'
    },
    isFilterActive(item) {
      return moment(item.splitEndTime) >= moment(this.nowActiveTime) && moment(item.splitStartTime) <= moment(this.nowActiveTime)
    },
    fixedTextScroll() {
      if(this.isScroll) {
        if(this.isQueryAudioText) {
          let scrollList = this.textAndCommentContent
          for(let i = 0; i < scrollList.length; i++) {
            let item = scrollList[i] || {}
            if(moment(item.splitEndTime) >= moment(this.nowActiveTime) && moment(item.splitStartTime) <= moment(this.nowActiveTime)) {
              let top = document.getElementById(`${this.filterTime(item.splitStartTime)}`).offsetTop
              document.getElementById('boardWrap').scrollTop = top
              break
            }
          }
        } else {
          let scrollList = this.textAndCommentContent[0].commentDataArray
          let id = scrollList[this.messageActiveIndex].publish_time
          let top = document.getElementById(`${this.filterTime(id)}`).offsetTop - 16
          console.log({id}, {top})
          document.getElementById('messageBox').scrollTop = top
        }
      }
    },
    exportLiveTextDY() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      this.isExport = true
      let API = liveApi.exportLiveTextDY(params)
      this.downloadLiveText(API, `直播话术_[${this.authorInfo.title}][${moment(this.authorInfo.publishTime).format('YYYY-MM-DD')}]`)
    },
    async downloadLiveText(API, name) {
      try {
        const res = await API;
        this.isExport = false
        downloadExcel(res, name);
      } catch(e) {
        this.$message.error("导出失败");
      }

    },
    getLiveTextDY() {
      return new Promise((resolve, reject) => {
        let params = {
          platform: this.platform,
          principalId: this.principalId,
          roomId: this.roomId
        }
        liveApi.getLiveTextDY(params).then(res => {
          console.log('getLiveTextDY')
          if(res.code == 200 && res.data.room.status == 'SUCCESS') {
            this.isQueryAudioText = true
          } else {
            this.isQueryAudioText = false
          }

          this.textAndCommentStatus.textTurnStatus = res.data.room.status
          this.getLiveTextAndCommentDY()
          resolve(true)
        })
      })
    },
    getLiveTextAndCommentDY() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.getLiveTextAndCommentDY(params).then(res => {
        if(res.code == 200) {
          let _data = res.data
          this.commentTotal = _data.commentDataArray.length
          this.textAndCommentContent = this.isQueryAudioText ? _data.list : [{ commentDataArray: _data.commentDataArray }]
        } else {
          this.$message.error(`获取主播文本和用户评论数据失败，${res.message}`)
        }
      })
    },
    getTextAndCommentStatusDY(action) {
      return new Promise((resolve, reject) => {
        let params = {
          action,
          platform: this.platform,
          roomIds: [this.roomId]
        }
        liveApi.getTextAndCommentStatusDY(params).then(res => {
          console.log({action})
          if(res.code == 200) {
            let taskProcess = (((res.data[0] || {}).taskRecords || [])[0] || {}).taskProcess
            switch(action) {
              case 'edouyin-live-reply':
                this.textAndCommentStatus.textStatus = taskProcess
                break;
              case 'edouyin-live-comment':
                this.textAndCommentStatus.commentStatus = taskProcess
                break;
            }
          } else {
            this.$message.error(`获取主播文本和用户评论数据失败，${res.message}`)
          }
          resolve(true)
        })
      })
    },
    backAnchorPoint() {
      this.isScroll = true
      this.scrollCount = 0
      if(this.nowActiveTime) {
        this.fixedTextScroll()
      } else {
        document.getElementById('boardWrap').scrollTop = 0
      }
    },
    formatSec(second) {
      let seconds = Math.floor(second)
      let daySec = 24 * 60 * 60;
      let hourSec = 60 * 60;
      let minuteSec = 60;
      let dd = Math.floor(seconds / daySec);
      let hh = Math.floor((seconds % daySec) / hourSec);
      let mm = Math.floor((seconds % hourSec) / minuteSec);
      let ss = seconds % minuteSec;
      if(dd > 0) {
        return `${dd}天${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
      }
      if(hh > 0) {
        return `${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
      }
      if(mm > 0) {
        return `${mm}分钟${ss ? (ss + "秒") : ''}`;
      } else {
        return ss + "秒";
      }
    },
    formatSecChart(second) {
      let seconds = Math.floor(second)
      let daySec = 24 * 60 * 60;
      let hourSec = 60 * 60;
      let minuteSec = 60;
      let dd = Math.floor(seconds / daySec);
      let hh = Math.floor((seconds % daySec) / hourSec);
      let mm = Math.floor((seconds % hourSec) / minuteSec);
      let ss = seconds % minuteSec;
      if(dd > 0) {
        return `${dd}天${hh}时${mm}分${ss ? (ss + "秒") : ''}`;
      }
      if(hh > 0) {
        return `${hh}时${mm}分${ss ? (ss + "秒") : ''}`;
      }
      if(mm > 0) {
        return `${mm}分${ss ? (ss + "秒") : ''}`;
      } else {
        return ss + "秒";
      }
    },
    //图表
    getLineChartOption() {
      let that = this
      let option = {
        legend: {
            type: 'scroll',
            top: 0,
            left: 60,
            itemWidth: 12,
            itemHeight: 12,
            inactiveColor: '#3C4252',
            icon: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
            textStyle: {
                fontSize: 12,
                color: '#333',
                padding: [0, 20, 0, 4]
            },
            pageFormatter: null,
        },
        tooltip: {
          trigger: 'axis',
          padding: [5, 10],
          formatter: function(params) {
              let pointTxt = ''
              let scatterTxt = ''
              let isScatter = false
              let time = undefined
              let duration = 0
              let timeS = undefined
              let durationS = 0
              
              params.map((item, index) => {
                if(item.componentSubType === "scatter") {
                  if(item.data) {
                    isScatter = true
                    let _data = item.data
                    timeS = _data.createTime
                    durationS = item.data.duration
                    
                    if(_data.highlight) {
                      scatterTxt += `<div style="max-width: 400px;white-space:pre-wrap">${item.marker}<span>高光时刻：${_data.highlightReason || '-'}</span></div>`
                    }
                    if(_data.lottery) {
                      scatterTxt += `<div style="max-width: 400px;white-space:pre-wrap">${item.marker}<span>福袋投放：${_data.prizeName || '-'}</span></div>`
                    }
                    if(_data.punishReason) {
                      scatterTxt += `<div style="max-width: 400px;white-space:pre-wrap">${item.marker}<span>直播违规：${_data.punishReason || '-'}</span></div>`
                    }
                  }
                } else {
                  time = item.axisValue
                  duration = item.data.duration
                  pointTxt += `<div>
                      ${item.marker}
                      <span>${item.seriesName}：${item.value || '-'}</span>
                  </div>`
                }
              })

              let baseTime = undefined
              let baseDuration = undefined
              let baseTxt = undefined
              if(timeS == time) {
                  baseTime = timeS
                  baseDuration = durationS
                  baseTxt = pointTxt + scatterTxt
              } else {
                if(isScatter) {
                  baseTime = timeS
                  baseDuration = durationS
                  baseTxt = scatterTxt
                } else {
                  baseTime = time
                  baseDuration = (duration * 60)
                  baseTxt = pointTxt
                }
              }
              
              return `<div>
                    <div>${baseTime}<span style="padding-left: 10px; color: rgba(255, 255, 255, .5);">开播:${baseDuration ? that.formatSecChart(baseDuration) : '0分'}</span></div>
                    ${baseTxt}
                </div>`
          }
        },
        grid: [
          {
            left: 75,
            right: 30,
            top: 60,
            bottom: 70,
          },
          {
            left: 75,
            right: 30,
            top: 45,
            bottom: 40,
          },
          {
            left: 75,
            right: 30,
            top: 45,
            bottom: 10,
          },
        ],
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
                lineStyle: {
                    color: '#999',
                }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#999',
                    fontSize: 10
                },
                formatter: function(value) {
                    return moment(value).format('HH:mm')
                },
                interval: 14,
                showMaxLabel: true
            },
            splitLine: {
                show: false
            },
            boundaryGap: false,
            data: [],
            position: 'bottom',
          }, 
          {
            name: '福袋高光',
            nameLocation: 'start',
            type: 'category',
            boundaryGap: false,
            position: 'bottom',
            data: [],
            gridIndex: 1,
            axisLine: {
                lineStyle: {
                    color: '#999',
                    type: 'dashed',
                }
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              z: 100
            },
          }, 
          {
            name: '违规记录',
            nameLocation: 'start',
            type: 'category',
            boundaryGap: false,
            position: 'bottom',
            data: [],
            gridIndex: 2,
            axisLine: {
                lineStyle: {
                    color: '#999',
                    type: 'dashed',
                }
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              z: 100
            },
          }, 
          {
            type: 'category',
            boundaryGap: false,
            position: 'bottom',
            data: [],
            gridIndex: 1,
            show: false,
            axisPointer: {
              type: 'none'
            },
          }, 
          {
            type: 'category',
            boundaryGap: false,
            position: 'bottom',
            data: [],
            gridIndex: 1,
            show: false,
            axisPointer: {
              type: 'none'
            },
          }
        ],
        yAxis: [{
            type: 'value',
            show: false,
        },{
            type: 'value',
            show: false,
            offset: 20,
            gridIndex: 1,
            show: false,
        },{
            type: 'value',
            show: false,
            offset: 20,
            gridIndex: 2,
            show: false,
        },{
            type: 'value',
            show: false,
            offset: 20,
            gridIndex: 1,
            show: false,
        }],
      }

      let legendKeys = []
      let legendList = {}
      let trendList = this.screenData.trend || []
      let xAxis = []
      let seriesList = []
      let dataList = {
        lpScreenLiveShowCount: [],
        lpScreenLiveMaxWatchUvByMinute: [],
        lpScreenLiveWatchUvByMinute: [],
        lpScreenLiveFansWatchUvByMinute: [],
        lpScreenLiveLeaveUvByMinute: [],
        lpScreenLiveLikeCount: [],
        lpScreenLiveCommentCount: [],
        lpScreenLiveFollowCount: [],
        lpScreenClueUv: [],
        lpScreenLiveIconClickCountByTime: [],
        lpScreenLiveClueBusinessCardClickCountByTime: [],
      }
      let pointByTimeY = []
      let punishByTimeY = []

      trendList.map((item, index) => {
        xAxis.push(item.statTimeMinute)
        if(index == 0) {
          // legendKeys = (Object.keys(item))
          legendKeys = [
            'lpScreenLiveShowCount',
            'lpScreenLiveMaxWatchUvByMinute',
            'lpScreenLiveWatchUvByMinute',
            'lpScreenLiveFansWatchUvByMinute',
            'lpScreenLiveLeaveUvByMinute',
            'lpScreenLiveLikeCount',
            'lpScreenLiveCommentCount',
            'lpScreenLiveFollowCount',
            'lpScreenClueUv',
            'lpScreenLiveIconClickCountByTime',
            'lpScreenLiveClueBusinessCardClickCountByTime',
            'statTimeMinute',
          ]
        }
        
        pointByTimeY[index] = null
        punishByTimeY[index] = null
        this.screenData.liveInfoByTime.map(m => {
          if(moment(m.createTime).format('YYYY-MM-DD HH:mm') === moment(item.statTimeMinute).format('YYYY-MM-DD HH:mm')) {
            if(m.highlight) { //高光
              delete m.punishReason
              pointByTimeY[index] = {value: 0, ...m, duration: moment(m.createTime).diff(moment(this.recordStartTime), 'seconds'), color: '#fff'}
            }
            if(m.lottery) { //福袋
              pointByTimeY[index] = {value: 0, ...m, duration: moment(m.createTime).diff(moment(this.recordStartTime), 'seconds'), color: '#1890ff'}
            }
            if(m.punishReason) { //违规
              punishByTimeY[index] = {value: 0, createTime: m.createTime, punishReason: m.punishReason, duration: moment(m.createTime).diff(moment(this.recordStartTime), 'seconds')}
            }
          }
        })

        let duration = (this.screenData.liveTimePointDuration || [])[index]

        dataList.lpScreenLiveShowCount.push({ value: item.lpScreenLiveShowCount, duration })
        dataList.lpScreenLiveMaxWatchUvByMinute.push({ value: item.lpScreenLiveMaxWatchUvByMinute, duration })
        dataList.lpScreenLiveWatchUvByMinute.push({ value: item.lpScreenLiveWatchUvByMinute, duration })
        dataList.lpScreenLiveFansWatchUvByMinute.push({ value: item.lpScreenLiveFansWatchUvByMinute, duration })
        dataList.lpScreenLiveLeaveUvByMinute.push({ value: item.lpScreenLiveLeaveUvByMinute, duration })
        dataList.lpScreenLiveLikeCount.push({ value: item.lpScreenLiveLikeCount, duration })
        dataList.lpScreenLiveCommentCount.push({ value: item.lpScreenLiveCommentCount, duration })
        dataList.lpScreenLiveFollowCount.push({ value: item.lpScreenLiveFollowCount, duration })
        dataList.lpScreenClueUv.push({ value: item.lpScreenClueUv, duration })
        dataList.lpScreenLiveIconClickCountByTime.push({ value: item.lpScreenLiveIconClickCountByTime, duration })
        dataList.lpScreenLiveClueBusinessCardClickCountByTime.push({ value: item.lpScreenLiveClueBusinessCardClickCountByTime, duration })
      })

      
      legendKeys.map((item, index) => {
        legendList[this.filterLegend(item)] = index < 3 ? true : false
        seriesList.push({
          name: this.filterLegend(item),
          type: 'line',
          data: dataList[item],
          ...this.seriesStyle(dataList[item], index),
          xAxisIndex: 0
        })
      })
      
      if(seriesList.length > 0) {
        let length = seriesList.length
        seriesList[length] = {
					type: 'scatter',
					symbolSize: 10,
          data: pointByTimeY,
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
            borderColor: '#ddd',
            borderWidth: 2,
            color: function (params) {
              return (params.data || {}).color
            }
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            }
          },
        }
        seriesList[length+1] = {
					type: 'scatter',
					symbolSize: 10,
          data: punishByTimeY,
          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            borderColor: '#ddd',
            borderWidth: 2,
            color: '#bd901a'
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            }
          },
        }

        let markLineY = []
        if(this.screenData.statTimeBySecond) {
          this.screenData.statTimeBySecond.map(item => {
            markLineY.push(null)
          })
        }
        seriesList[length+2] = {
          type: 'line',
          data: markLineY,
          tooltip: {
            show:false,
          },
          xAxisIndex: 3,
          yAxisIndex: 3,
          barWidth: 6,
          markLine: {
            symbol:'none',
            animation: false,
            label: {
              position: 'start',
              distance: [0 , -30],
              padding: [5, 10],
              backgroundColor: '#f50',
              color: '#fff',
              formatter: function(params) {
                return moment(params.value).format('HH:mm:ss')
              }
            },
            data: [{
              xAxis: this.nowActiveTime,
              lineStyle: {
                color: '#f50',
                type: 'solid'
              }
            }],
            // silent: true
          }
        }
      }
      
      option.legend.selected = Object.keys(this.legendList).length == 0 ? legendList : this.legendList
      option.xAxis[0].data = xAxis

      option.xAxis[1].data = xAxis
      // option.xAxis[1].show = pointByTimeY.filter(item => item).length > 0

      option.xAxis[2].data = xAxis
      option.xAxis[2].show = punishByTimeY.filter(item => item).length > 0

      option.xAxis[3].data = this.screenData.statTimeBySecond || []
      option.series = seriesList
      
      return option;
    },
    getLegend(legend) {
      this.legendList = legend
    },
    seriesStyle(data, index) {
      let _data = []

      if(data) {
        data.map(item => {
          _data.push(item.value)
        })
      }
      return {
        type: 'line',
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: `rgba(${colors[index]}, 0.5)`,
                },
                {
                  offset: 0.8,
                  color: `rgba(${colors[index]}, 0)`,
                },
              ],
              false
            ),
          },
        },
        itemStyle: {
          normal: {
            color: `rgba(${colors[index]}, 1)`,
          },
        },
        smooth: true,
        symbol: 'none',
        markPoint:{
          label: {
            color: '#333',
            show: true,
          },
          symbol: "pin",
          data: [ {type: Math.max(..._data) ? 'max' : undefined} ],
        }
      }
    },
    filterLegend(type) {
      let txt = ''
      switch(type) {	
        case 'lpScreenLiveShowCount':
          txt = '曝光次数'
          break;
        case 'lpScreenLiveMaxWatchUvByMinute':
          txt = '在线人数'
          break;
        case 'lpScreenLiveWatchUvByMinute':
          txt = '进入人数'
          break;
        case 'lpScreenLiveFansWatchUvByMinute':
          txt = '进入粉丝数'
          break;
        case 'lpScreenLiveLeaveUvByMinute':
          txt = '离开人数'
          break;
        case 'lpScreenLiveLikeCount':
          txt = '点赞量'
          break;
        case 'lpScreenLiveCommentCount':
          txt = '评论量'
          break;
        case 'lpScreenLiveFollowCount':
          txt = '增粉量'
          break;
        case 'lpScreenClueUv':
          txt = '全场景线索人数'
          break;
        case 'lpScreenLiveIconClickCountByTime':
          txt = '小风车点击次数'
          break;
        case 'lpScreenLiveClueBusinessCardClickCountByTime':
          txt = '卡片点击次数'
          break;
      }
      return txt
    },
    thisIndex(index, op) {
      let time = op.xAxis[1].data[index]
      let diff = moment(time).diff(moment(this.recordStartTime), 'seconds')
      if((diff ?? '') !== '') {
        this.$emit('changeTime', diff)
      }
      // let duration = data.data.duration
      // if((duration ?? '') !== '') {
      //   this.$emit('changeTime', data.data.duration * 60)
      // }
    },
    translationLiveForText() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.translationLiveForText(params).then(res => {
        if(res.code == 200) {
          this.$message.success(`正在处理中`)
          this.getLiveTextDY()
        } else {
          this.$message.error(`转写失败，${res.message}`)
        }
      })
    },
    goCurrentTime(time) {
      let diff = moment(time).diff(moment(this.recordStartTime), 'seconds')
      console.log({diff})
      this.$emit('changeTime', diff)
    }
  }
}
</script>

<style scoped lang="less">
.trend-box {
  .main-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .time-click {
    cursor: pointer;
  }
  .chart {
    height: 360px;
  }
  .content {
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
    .note {
      margin-bottom: 20px;
      font-size: 12px;
      color: #999;
      text-align: right;
    }
    .header-box {
      .row {
          display: flex;
          justify-content: space-between;
          position: relative;
          .col {
            position: relative;
            width: 48%;
          }
      }
    }
  }
  .board-content {
    position: relative;
    &.border::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 1px solid #c9c9c9;
    }
    .anchor-point {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      z-index: 10;
      .anchor {
        width: 25px;
        height: 25px;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        background-image: url("../../../../assets/icon/icon_anchor.svg");
        cursor: pointer;
      }
    }
  }
  .board-wrap {
    position: relative;
    padding: 0 10px;
    margin: 0 -10px;
    overflow: auto;
    line-height: 1.5em;
    .row {
        display: flex;
        justify-content: space-between;
        position: relative;
        min-height: 200px;
        & + .row {
          margin-top: 16px;
        }
        .col {
          position: relative;
          width: 48%;
        }
    }
    &.is-convert {
      max-height: 45em;
      
      .row {
        padding: 10px;
        background-color: rgba(0, 0, 0, .05);
        &::before {
          content: '';
          position: absolute;
          top: 1px;
          left: -6px;
          width: 12px;
          height: 12px;
          border: 2px solid #999;
          border-radius: 50%;
          background-color: #fff;
        }
        .time-point {
          position: absolute;
          top: -13px;
          left: 0;
          height: 12px;
          font-size: 12px;
          color: #999;
        }
        .detail {
          padding-top: 10px;
        }
        &.active {
          &::before {
            border-color: #1890ff;
          }
          .time-point, .detail {
            color: #1890ff;
          }
        }
        .message-box {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          max-height: 100%;
        }
      }
    }
    &.un-convert {
      .message-wrap {
        padding: 16px;
        background-color: rgba(0, 0, 0, .05);
        .message-box {
          max-height: 45em;
        }
      }
    }
    .message-box {
      padding: 5px 10px;
      background-color: rgba(0, 0, 0, .08);
      line-height: 1.5em;
      overflow-y: auto;
      .message {
        font-size: 12px;
        .time {
          color: #999;
        }
        &.active {
          color: #1890ff;
          .time {
            color: #1890ff;
          }
        }
      }
    }
  }
  .convert-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .convert-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      .convert {
        width: 60px;
        height: 60px;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        background-image: url("../../../../assets/icon/icon_convert.svg");
      }
      .txt {
        font-size: 14px;
        color: #1890ff;
      }
    }
  }
}
</style>