<template>
  <div class="player" :id="id"></div>
</template>

<script>
import Player from 'xgplayer'
import 'xgplayer/dist/index.min.css'
import HlsPlugin from 'xgplayer-hls'
const mediaConfig = {
  type: 'file', // or 'media-source' or 'webrtc'
  video: {
    contentType: 'video/mp4;codecs="avc1.4d0028"', // AVC Main, Level 4
    width: 800, // width of the video
    height: 600, // height of the video
    bitrate: 10000, // number of bits used to encode 1s of video
    framerate: 30 // number of frames making up that 1s.
  },
  audio: {
    contentType: 'audio/ogg; codecs=vorbis', // valid content type
    channels: 2, // audio channels used by the track
    bitrate: 132700, // number of bits used to encode 1s of audio
    samplerate: 5200 // number of audio samples making up that 1s.
  }
}

Player.probeConfigSupported(mediaConfig).then((decodingInfo) => {
  console.log({decodingInfo})
})
const result = Player.isHevcSupported()
console.log({result})

export default {
  name: 'xgplayer',
  props: {
    id: {
      type: String,
      default: 'videoPlayer'
    },
    url: {
      type: String,
      default: '',
    },
    changeTime: {
      type: Number,
      default: 0
    },
    isHls: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      player: null,
    };
  },
  watch: {
    changeTime: {
      handler: function(val) {
        if (this.player) {
          this.player.play()
          this.player.currentTime = val
        } else {
          this.initPlayer();
        }
      }
    }
  },
  mounted() {
    this.initPlayer();
  },
  methods: {
    initPlayer() {
      if (!this.url) return console.warn('url is not esist');
      this.$nextTick(() => {
        const config = {
          id: this.id,
          url: this.url,
          videoInit: false,
          preload: 'auto',
          pip: true, //画中画
          pipConfig: {
            bottom: 100,
            right: 100,
            width: 320,
            height: 180,
          },
          volume: false, //音量
          playbackRate: false,//倍数
          // download: true,
          plugins: this.isHls ? [HlsPlugin] : null,
        };
        console.log({config})
        const player = new Player(config);

        if (player) {
          this.player = player;
          
          this.player.on('play', data => {
            this.$emit('xgPlayerEvent', data.currentTime);
          });
          this.player.on('seeked', data => {
            this.$emit('xgPlayerEvent', data.currentTime);
          });
          this.player.on('timeupdate', data => {
            this.$emit('xgPlayerEvent', data.currentTime);
          });
        }
      })
    },
  },
};
</script>

<style scoped lang="less">
</style>

