import moment from 'moment'
import FormatUtil from "@/utils/FormatUtil";
var _color = 'rgba(255, 255, 255, .6)'

// function formatSecToStr(minutes) {
//     return minutes > 60 ? `${Math.floor(minutes / 60)}小时${(minutes % 60)}分` : `${minutes}分`
// }
function formatSec(second) {
    let seconds = Math.floor(second)
    let daySec = 24 * 60 * 60;
    let hourSec = 60 * 60;
    let minuteSec = 60;
    let dd = Math.floor(seconds / daySec);
    let hh = Math.floor((seconds % daySec) / hourSec);
    let mm = Math.floor((seconds % hourSec) / minuteSec);
    let ss = seconds % minuteSec;
    if(dd > 0) {
        return `${dd}天${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
    }
    if(hh > 0) {
        return `${hh}小时${mm}分钟${ss ? (ss + "秒") : ''}`;
    }
    if(mm > 0) {
        return `${mm}分钟${ss ? (ss + "秒") : ''}`;
    } else {
        return ss + "秒";
    }
}

var option = {
    legend: {
        top: 0,
        left: 60,
        itemWidth: 12,
        itemHeight: 12,
        inactiveColor: '#3C4252',
        icon: 'path://M512.005117 958.708971C265.683035 958.708971 65.290005 758.316965 65.290005 511.99386c0-246.310825 200.39303-446.703855 446.715111-446.703855 246.310825 0 446.703855 200.39303 446.703855 446.703855C958.708971 758.316965 758.316965 958.708971 512.005117 958.708971zM512.005117 169.716356c-188.738595 0-342.289784 153.545048-342.289784 342.277504 0 188.738595 153.551188 342.289784 342.289784 342.289784 188.733479 0 342.278527-153.551188 342.278527-342.289784C854.283644 323.261405 700.738595 169.716356 512.005117 169.716356z',
        textStyle: {
            fontSize: 12,
            color: '#333',
            padding: [0, 20, 0, 4]
        },
    },
    tooltip: {
        trigger: 'axis',
        padding: [5, 10],
        formatter: function(params) {
            let txt = ''
            let duration = 0
            params.map((item, index) => {
                if(index == 0) {
                    duration = item.data.duration
                }
                txt += `<div>
                    ${item.marker}
                    <span>${item.seriesName}：${item.value || '-'}</span>
                </div>`
            })
            return `<div>
                <div>${moment(params[0].axisValue).format('YYYY-MM-DD HH:mm:ss')}<span style="padding-left: 10px; color: rgba(255, 255, 255, .5);">开播:${duration ? formatSec(duration * 60) : '0分'}</span></div>
                ${txt}
            </div>`
            // return `<div>
            //     <div>${moment(params[0].axisValue).format('YYYY-MM-DD HH:mm')}</div>
            //     ${txt}
            // </div>`
        }
    },
    grid: {
        top: 45,
        left: 50,
        right: 20,
        // bottom: 60,
        bottom: 30,
    },
    xAxis: {
        type: 'category',
        axisLine: {
            lineStyle: {
                color: '#999',
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#999',
                fontSize: 10
            },
            formatter: function(value) {
                return moment(value).format('HH:mm')
            },
            interval: 14,
            showMaxLabel: true
        },
        splitLine: {
            show: false
        },
        boundaryGap: false,
        data: [],
    },
    // yAxis: [
    //     {
    //         type: 'value',
    //         show: false,
    //     },
    //     {
    //         show: false,
    //     },
    //     {
    //         show: false,
    //     }
    // ],
    yAxis: [{
        type: 'value',
        show: false,
    }],
    // dataZoom: [
    //     {
    //         type: 'inside',
    //         start: 0,
    //         end: 20,
    //         zoomLock: true,
    //         zoomOnMouseWheel: false,
    //     },
    //     {
    //         start: 0,
    //         end: 20
    //     }
    // ],
    series: []
}

export default option