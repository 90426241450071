<template>
  <div class="trend-box">
    <div class="main-title" style="padding: 20px 20px 0;">综合趋势</div>
    <div class="chart" v-if="chartData.time">
      <BaseCharts
        ref="BaseCharts"
        key="line"
        :style="{width:'100%',height:'100%'}"
        :option="getLineChartOption()"
        :isLimitLegend="true"
        :limitLength="3"
        :isClick="true"
        @thisIndex="thisIndex"
      />
    </div>
    <div v-else style="display: flex; align-items: center; justify-content: center; height: 300px;">
      统计数据处理中，请稍候
    </div>

    <div class="content">
      <div class="note">提示：双击话术/评论时间联动直播回放及趋势数据</div>
      <div class="header-box">
        <div class="row">
          <div class="col">
            <div class="main-title">主播话术 <a-button type="link" size="large" :disabled="!isQueryAudioText || isExport" :loading="isExport" style="height: 20px" @click="exportLiveText"><a-icon type="download" /></a-button></div>
          </div>
          <div class="col">
            <div class="main-title">用户评论({{ commentTotal || 0 }})</div>
          </div>
        </div>
      </div>
      
      <div :class="['board-content', isQueryAudioText ? 'border' : '']">
        <div class="anchor-point" v-if="isQueryAudioText" @click="backAnchorPoint">
          <a-tooltip placement="left">
            <template slot="title">
              <span>回到当前播放位置</span>
            </template>
            <div class="anchor"></div>
          </a-tooltip>
        </div>
        <div :class="['board-wrap', isQueryAudioText ? 'is-convert' : 'un-convert']" id="boardWrap">
          <div :class="['row', isFilterActive(item) ? 'active' : '']" :id="`${filterTime(item.splitStartTime)}`" v-for="(item, index) in (textAndCommentContent || [])" :key="item.id">
            <div class="col" v-if="isQueryAudioText">
              <div class="time-point time-click" @dblclick="goCurrentTime(item.splitStartTime)">{{ filterTime(item.splitStartTime) }}</div>
              <div class="detail">{{ item.text }}</div>
            </div>
            <div class="col" v-else>
              <template v-if="videoData.url">
                <div class="convert-wrap" v-if="(videoData.state == 'Archive' || videoData.state == 'Unknown') && (videoData.is_restore_completed === 0 || videoData.is_restore_completed === 1 || videoData.is_restore_completed === 3)">
                  <div class="convert-box">
                    <div>
                      <div class="txt">请先完成直播回放解冻</div>
                    </div>
                  </div>
                </div>
                <template v-if="videoData.is_restore_completed === 2 || videoData.state == 'Standard' || videoData.state == 'IA'">
                  <div class="convert-wrap" v-if="roomStatus == 'PROCESSING'">
                    <div class="convert-box">
                      <div>
                        <a-icon type="loading" />
                        <div class="txt">转写中，预计10分钟完成</div>
                      </div>
                    </div>
                  </div>
                  <div class="convert-wrap" v-else>
                    <div class="convert-box" @click="translationLiveForText">
                      <div>
                        <div class="convert"></div>
                        <div class="txt">开始转写</div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div class="col message-wrap">
              <div v-if="isTimeout && index == 0 && commentTotal == 0">
                开播超过一个月的直播评论暂不显示
              </div>
              <div class="message-box" id="messageBox" v-if="!isTimeout || commentTotal > 0">
                <div :class="['message', !isQueryAudioText && messageActiveIndex === mIndex ? 'active' : '']" v-for="(list, mIndex) in (item.commentDataArray || [])" :key="list.id" :id="`${filterTime(list.publish_time)}`">
                  <span class="time time-click" @dblclick="goCurrentTime(list.publish_time)">{{ filterTime(list.publish_time) }}</span>
                  <span class="time">{{ (list || {}).user.nickname || '-' }}：</span>{{ list.content || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liveApi from '@/api/live'
import BaseCharts from "@/components/BaseChart";
import chartLine from "../chartLine";
import deepClone from "@/utils/deepClone";
import echarts from "echarts";
import moment from 'moment';
import { downloadExcel } from "@/utils";

const colors = ["40, 189, 69", "255, 202, 92", "254, 140, 140", "186, 140, 254", "129, 175, 255", "92, 230, 241", "244, 50, 199"]

export default {
  props: {
    roomId: String,
    platform: String,
    principalId: String,
    authorInfo: {
      type: Object,
      default: () => {}
    },
    activeTime: [String, Number],
    videoData: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BaseCharts
  },
  watch: {
    authorInfo: {
      handler: function(data) {
        if(moment(new Date()).subtract(1,'months') > moment(data.publishTime)) {
          this.isTimeout = true
        }
        this.recordStartTime = data.recordStartTime
      },
      immediate: true,
      deep: true
    },
    activeTime: {
      handler: function(data) {
        console.log('activeTime', data)
        if((data ?? '') !== '') {
          let val = Number(data)
          let statisticsEvenLifeTime = this.chartData
          if(statisticsEvenLifeTime['time']) {
            let index = statisticsEvenLifeTime['live_duration'].indexOf(val)
            let rang = (statisticsEvenLifeTime['live_duration'] || []).length ? Number((val / (statisticsEvenLifeTime['live_duration'] || []).length * 100).toFixed(0)) : 0
            rang < 10 ? 10 : rang

            this.nowActiveTime = index != -1 ? statisticsEvenLifeTime['time'][index] : this.nowActiveTime
            this.rangZoom = [rang-10 , rang+10]

            if(!this.isQueryAudioText) {
              let list = this.textAndCommentContent[0].commentDataArray || []
              for(let i = 0; i < list.length; i++) {
                let start = moment(list[i].publish_time).format('YYYY-MM-DD HH:mm:00')
                if(start == this.nowActiveTime || i == list.length - 1) {
                  this.messageActiveIndex = i
                  break
                } else if(start > this.nowActiveTime) {
                  this.messageActiveIndex = i ? (i - 1) : i
                  break
                }
              }
            }

            this.fixedTextScroll()
            this.scrollCount = 0
          }
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      isQueryAudioText: false,
      textAndCommentContent: [],
      commentTotal: 0,
      isExport: false,
      roomStatus: '',
      nowActiveTime: '',
      rangZoom:[0, 20],
      isTimeout: false,
      isScroll: true,
      scrollCount: 0,
      chartData: {},
      recordStartTime: '',
      messageActiveIndex: undefined,
    }
  },
  created() {
    this.getLiveChartData()
    this.getLiveText()
  },
  mounted() {
    var that = this
    document.getElementById('boardWrap').addEventListener("scroll", function () {
      that.scrollCount++;
      if(that.scrollCount > 5) {
        that.isScroll = false
      }
    })
  },
  methods: {
    filterTime(time) {
      return time ? moment(time).format('HH:mm:ss') : '-'
    },
    isFilterActive(item) {
      return moment(item.splitEndTime) >= moment(this.nowActiveTime) && moment(item.splitStartTime) <= moment(this.nowActiveTime)
    },
    isFilterMessageActive(item) {
      let start = moment(item.publish_time).format('YYYY-MM-DD HH:mm:00')
      let end = moment(item.publish_time).format('YYYY-MM-DD HH:mm:59')
      return moment(end) >= moment(this.nowActiveTime) && moment(start) <= moment(this.nowActiveTime)
    },
    fixedTextScroll() {
      // if(this.isScroll) {
      //   let scrollList = this.textAndCommentContent
      //   for(let i = 0; i < scrollList.length; i++) {
      //     let item = scrollList[i] || {}
      //     if(moment(item.splitEndTime) >= moment(this.nowActiveTime) && moment(item.splitStartTime) <= moment(this.nowActiveTime)) {
      //       let top = document.getElementById(`${this.filterTime(item.splitStartTime)}`).offsetTop
      //       document.getElementById('boardWrap').scrollTop = top
      //       break
      //     }
      //   }
      // }
      console.log(this.isScroll)
      if(this.isScroll) {
        if(this.isQueryAudioText) {
          let scrollList = this.textAndCommentContent
          for(let i = 0; i < scrollList.length; i++) {
            let item = scrollList[i] || {}
            if(moment(item.splitEndTime) >= moment(this.nowActiveTime) && moment(item.splitStartTime) <= moment(this.nowActiveTime)) {
              let top = document.getElementById(`${this.filterTime(item.splitStartTime)}`).offsetTop
              document.getElementById('boardWrap').scrollTop = top
              break
            }
          }
        } else {
          let scrollList = this.textAndCommentContent[0].commentDataArray
          let id = scrollList[this.messageActiveIndex].publish_time
          let top = document.getElementById(`${this.filterTime(id)}`).offsetTop - 16
          document.getElementById('messageBox').scrollTop = top
        }
      }
    },
    exportLiveText() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      this.isExport = true
      let API = liveApi.exportLiveText(params)
      this.downloadLiveText(API, `直播话术_[${this.authorInfo.title}][${moment(this.authorInfo.publishTime).format('YYYY-MM-DD')}]`)
    },
    async downloadLiveText(API, name) {
      try {
        const res = await API;
        this.isExport = false
        downloadExcel(res, name);
      } catch(e) {
        this.$message.error("导出失败");
      }

    },
    getLiveChartData() {
      let params = {
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.getLiveChartData(params).then(res => {
        if(res.code == 200) {
          this.chartData = res.data || {}
        } else {
          this.$message.error(`获取综合趋势失败，${res.message}`)
        }
      })
    },
    getLiveText() {
      let params = {
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.getLiveText(params).then(res => {
        if(res.code == 200 && res.data.list && res.data.list.length > 0) {
          this.isQueryAudioText = true
        } else {
          this.isQueryAudioText = false
        }
        this.getLiveTextAndComment()
      })
    },
    getLiveTextAndComment() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.getLiveTextAndComment(params).then(res => {
        if(res.code == 200) {
          let _data = res.data
          this.commentTotal = _data.commentDataArray.length
          this.textAndCommentContent = this.isQueryAudioText ? _data.list : [{ commentDataArray: _data.commentDataArray }]

          this.roomStatus = _data.room.status
        } else {
          this.$message.error(`获取主播文本和用户评论数据失败，${res.message}`)
        }
      })
    },
    backAnchorPoint() {
      this.isScroll = true
      this.scrollCount = 0
      if(this.nowActiveTime) {
        this.fixedTextScroll()
      } else {
        document.getElementById('boardWrap').scrollTop = 0
      }
    },
    //图表
    getLineChartOption() {
      let option = null;
      if (!option) {
          option = deepClone(chartLine);
      }

      let legendList = {}
      let index = 0
      let statisticsEvenLifeTime = this.chartData
      // let statisticsEvenLifeTime = this.authorInfo.statisticsEvenLifeTime || {}
      let xAxis = statisticsEvenLifeTime['time'] || []
      let seriesList = [
        {
          name: '在线人数',
          data: (statisticsEvenLifeTime['watch_cnt'] || []).map((item, index) => {
            return { value: item, duration: (statisticsEvenLifeTime['live_time_point_duration'] || [])[index] }
          }),
          barWidth: 6,
          markLine: {
            symbol:'none',
            animation: false,
            label: {
              position: 'start',
              distance: [0 , -30],
              padding: [5, 10],
              backgroundColor: '#f50',
              color: '#fff',
              formatter: function(params) {
                return moment(params.value).format('HH:mm:ss')
              }
            },
            data: [{
              xAxis: this.nowActiveTime,
              lineStyle: {
                color: '#f50',
                type: 'solid'
              }
            }],
            // silent: true
          },
          ...this.seriesStyle(0),
        },
        {
          name: '点赞量',
          data: (statisticsEvenLifeTime['like_count'] || []).map((item, index) => {
            return { value: item, duration: (statisticsEvenLifeTime['live_time_point_duration'] || [])[index] }
          }),
          // yAxisIndex:'1',
          ...this.seriesStyle(1),
        },
        {
          name: '评论量',
          data: (statisticsEvenLifeTime['comment_count'] || []).map((item, index) => {
            return { value: item, duration: (statisticsEvenLifeTime['live_time_point_duration'] || [])[index] }
          }),
          // yAxisIndex:'2',
          ...this.seriesStyle(2),
        },
      ]

      option.xAxis.data = xAxis
      option.series = seriesList
      
      return option;
    },
    seriesStyle(index) {
      return {
        type: 'line',
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: `rgba(${colors[index]}, 0.5)`,
                },
                {
                  offset: 0.8,
                  color: `rgba(${colors[index]}, 0)`,
                },
              ],
              false
            ),
          },
        },
        itemStyle: {
          normal: {
            color: `rgba(${colors[index]}, 1)`,
          },
        },
        smooth: true,
        symbol: 'none',
        markPoint:{
          label: {
            color: '#333',
            show: true,
          },
          symbol: "pin",
          data: [ {type: 'max'} ],        
        }
      }
    },
    filterLegend(type) {
      let txt = ''
      switch(type) {	
        case 'comment_count':
          txt = '评论量'
          break;
        case 'comment_user_count':
          txt = '评论用户数量'
          break;
        case 'like_count':
          txt = '点赞量'
          break;
        case 'like_user_count':
          txt = '点赞用户数'
          break;
        case 'watch_cnt':
          txt = '观看人次'
          break;
        case 'watch_cnt_acc':
          txt = '观看人数'
          break;
      }
      return txt
    },
    thisIndex(index) {
      let duration = (this.chartData['live_duration'] || [])[index]
      if((duration ?? '') !== '') {
        this.$emit('changeTime', duration * 60)
      }
      // let duration = data.data.duration
      // if((duration ?? '') !== '') {
      //   this.$emit('changeTime', data.data.duration * 60)
      // }
    },
    translationLiveForText() {
      let params = {
        isQueryAudioText: this.isQueryAudioText,
        platform: this.platform,
        principalId: this.principalId,
        roomId: this.roomId
      }
      liveApi.translationLiveForText(params).then(res => {
        if(res.code == 200) {
          this.$message.success(`正在处理中`)
          this.getLiveText()
        } else {
          this.$message.error(`转写失败，${res.message}`)
        }
      })
    },
    goCurrentTime(time) {
      let diff = moment(time).diff(moment(this.recordStartTime), 'seconds')
      console.log({diff})
      this.$emit('changeTime', diff)
    }
  }
}
</script>

<style scoped lang="less">
.trend-box {
  .main-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .time-click {
    cursor: pointer;
  }
  .chart {
    height: 360px;
  }
  .content {
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
    .note {
      margin-bottom: 20px;
      font-size: 12px;
      color: #999;
      text-align: right;
    }
    .header-box {
      .row {
          display: flex;
          justify-content: space-between;
          position: relative;
          .col {
            position: relative;
            width: 48%;
          }
      }
    }
  }
  .board-content {
    position: relative;
    &.border::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 1px solid #c9c9c9;
    }
    .anchor-point {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      z-index: 10;
      .anchor {
        width: 25px;
        height: 25px;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        background-image: url("../../../../assets/icon/icon_anchor.svg");
        cursor: pointer;
      }
    }
  }
  .board-wrap {
    position: relative;
    padding: 0 10px;
    margin: 0 -10px;
    overflow: auto;
    line-height: 1.5em;
    .row {
        display: flex;
        justify-content: space-between;
        position: relative;
        min-height: 200px;
        & + .row {
          margin-top: 16px;
        }
        .col {
          position: relative;
          width: 48%;
        }
    }
    &.is-convert {
      max-height: 45em;
      
      .row {
        padding: 10px;
        background-color: rgba(0, 0, 0, .05);
        &::before {
          content: '';
          position: absolute;
          top: 1px;
          left: -6px;
          width: 12px;
          height: 12px;
          border: 2px solid #999;
          border-radius: 50%;
          background-color: #fff;
        }
        .time-point {
          position: absolute;
          top: -13px;
          left: 0;
          height: 12px;
          font-size: 12px;
          color: #999;
        }
        .detail {
          padding-top: 10px;
        }
        &.active {
          &::before {
            border-color: #1890ff;
          }
          .time-point, .detail {
            color: #1890ff;
          }
        }
        .message-box {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          max-height: 100%;
        }
      }
    }
    &.un-convert {
      .message-wrap {
        padding: 16px;
        background-color: rgba(0, 0, 0, .05);
        .message-box {
          max-height: 45em;
        }
      }
    }
    .message-box {
      padding: 5px 10px;
      background-color: rgba(0, 0, 0, .08);
      line-height: 1.5em;
      overflow-y: auto;
      .message {
        font-size: 12px;
        .time {
          color: #999;
        }
        &.active {
          color: #1890ff;
          .time {
            color: #1890ff;
          }
        }
      }
    }
  }
  .convert-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .convert-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      .convert {
        width: 60px;
        height: 60px;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        background-image: url("../../../../assets/icon/icon_convert.svg");
      }
      .txt {
        font-size: 14px;
        color: #1890ff;
      }
    }
  }
}
</style>